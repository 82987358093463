//our CSS file
import './platform.scss';
//fontawesome
import '@fortawesome/fontawesome-free/js/all.min.js';
//vue
import Vue from 'vue';
window.Vue = Vue;
//moment
import moment from 'moment';
window.moment = moment;

//noSleep screen
import NoSleep from 'nosleep.js';
window.NoSleep = NoSleep;

//map
import 'leaflet/dist/leaflet.js';
import 'leaflet/dist/leaflet.css';

import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.js'


//utils
import init_custom_comps from './components/imports.js';
init_custom_comps(Vue);

window.getErrorText = function (error) {
	if (isset(() => error.response.data.errors) && Object.keys(error.response.data.errors).length > 0) {
		for (var key in error.response.data.errors) {
			if (error.response.data.errors.hasOwnProperty(key)) {
				return error.response.data.errors[key][0];
			}
		}
	}
	return 'NIEZNANY BŁĄD';
};

Vue.use({
	install: function (Vue, options) {
		Vue.prototype.getErrorText = window.getErrorText;
		Vue.prototype.axios = window.axios;
	},
});

//axios
import axios from 'axios';
window.axios = axios;
axios.interceptors.request.use(function (config) {
	if ((config.method === 'post' || config.method === 'patch') && config.url.startsWith('/')) {
		config.headers['X-CSRFToken'] = window.getCookie('csrftoken');
	}
	return config;
});

import { Html5Qrcode } from 'html5-qrcode';
window.Html5Qrcode = Html5Qrcode;

import headerComponent from './epack_components/header-component.vue';
Vue.component('header-component', headerComponent);

import labelPreviewComponent from './epack_components/label-preview-component.vue';
Vue.component('label-preview-component', labelPreviewComponent);

import addButtonComponent from './epack_components/add-button-component.vue';
Vue.component('add-button-component', addButtonComponent);
