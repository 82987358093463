<template>
    <div>
		<div class="is-flex is-flex-direction-column ">
			<h1 class="title is-size-5-mobile">{{ title }}</h1>
			<h2 class="subtitle mb-1 is-size-6-mobile">{{ subtitle }}</h2>
			<h3 class="is-size-6-mobile">{{ shipment }}</h3>
			<h3 class="has-text-danger-dark is-size-7-mobile">{{ status }}</h3>
			<h3 v-if="ref_shipment" class="has-text-danger-dark is-size-7-mobile" @click="goToRefShip">Ref. ship. link: <span class="short-ref">{{ shortRefShipment }}</span> </h3>
            <hr />
		</div>
    </div>
</template>
<script>
export default {
	name: 'headerComponent',
	props: {
		title: {
			type: String,
		},
		subtitle: {
			type: String,
		},
		status: {
			type: String,
		},
		shipment: {
			type: String,
		},
		ref_shipment: {
			type: String,
		},
	},
	methods: {
		goToRefShip() {
			let redirectURL= $getUrl('frontend:shipment:single', {type: 'sending', uuid: this.ref_shipment})
			window.open(redirectURL, '_self');
		}
	},
	computed :{
		shortRefShipment(){
			if (this.ref_shipment) {
				return this.ref_shipment.slice(-12)
				}
		}
	},
};
</script>
<style scoped>

.short-ref {
	cursor:pointer;

}
.short-ref:hover {
	text-decoration:underline;
}


</style>
