var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "buttons add-button" }, [
    _c(
      "a",
      {
        staticClass: "button has-text-success is-light is-medium",
        attrs: { href: _vm.newShipmenURL },
      },
      [_vm._m(0)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [_c("i", { staticClass: "icon fas fa-plus" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }