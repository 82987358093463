import { render, staticRenderFns } from "./add-button-component.vue?vue&type=template&id=674fd449&scoped=true"
import script from "./add-button-component.vue?vue&type=script&lang=js"
export * from "./add-button-component.vue?vue&type=script&lang=js"
import style0 from "./add-button-component.vue?vue&type=style&index=0&id=674fd449&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "674fd449",
  null
  
)

export default component.exports