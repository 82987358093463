<template>
    <div class="columns">
        <div class="column has-text-centered">
            <img v-if="toBase64" :src="$trans('data:image/bmp;base64,' + toBase64)" class="is-align-self-center"/>
            <p v-else>preview unavailable</p>
        </div>
    </div>
</template>
<script>
export default {
    name:'labelPreviewComponent',
    props:{
        shipment:{
            type: Object,
            required: true
        },
        labelBytes: [],
    },
    computed: {
		toBase64() {
			const base64String = btoa(
				String.fromCharCode(...new Uint8Array(this.labelBytes))
			);
			return base64String;
		},
	},
}
</script>

