var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c("div", { staticClass: "column has-text-centered" }, [
      _vm.toBase64
        ? _c("img", {
            staticClass: "is-align-self-center",
            attrs: { src: _vm.$trans("data:image/bmp;base64," + _vm.toBase64) },
          })
        : _c("p", [_vm._v("preview unavailable")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }