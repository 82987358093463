<template>
  <footer class="buttons add-button">
		<a class="button has-text-success is-light is-medium" :href="newShipmenURL">
			<div class=""><i class="icon fas fa-plus"></i></div>
		</a>
	  </footer>
</template>

<script>
export default {
	name:'addButtonComponent',
	computed: {
	 newShipmenURL() {
            const url = $getUrl('frontend:shipment:new')
            if (url == 'None') {
                return '#';
            } else {
                return url;
            }
        },
	}

}

</script>
<style scoped>

.add-button {
		position: fixed;
		bottom :10px;
		right: 20px
	}
</style>
