var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "is-flex is-flex-direction-column" }, [
      _c("h1", { staticClass: "title is-size-5-mobile" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "subtitle mb-1 is-size-6-mobile" }, [
        _vm._v(_vm._s(_vm.subtitle)),
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "is-size-6-mobile" }, [
        _vm._v(_vm._s(_vm.shipment)),
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "has-text-danger-dark is-size-7-mobile" }, [
        _vm._v(_vm._s(_vm.status)),
      ]),
      _vm._v(" "),
      _vm.ref_shipment
        ? _c(
            "h3",
            {
              staticClass: "has-text-danger-dark is-size-7-mobile",
              on: { click: _vm.goToRefShip },
            },
            [
              _vm._v("Ref. ship. link: "),
              _c("span", { staticClass: "short-ref" }, [
                _vm._v(_vm._s(_vm.shortRefShipment)),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("hr"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }